<script>
export default {};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                        <p class="text-muted">Sign in to continue to Nazox.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-user-2-line auti-custom-input-icon"
                            ></i>
                            <label for="username">Username</label>
                            <input
                              type="text"
                              class="form-control"
                              id="username"
                              placeholder="Enter username"
                            />
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                            />
                          </div>

                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                              >Remember me</label
                            >
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              tag="a"
                              to="/auth/recoverpwd-1"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/auth/register-1"
                            class="font-weight-medium text-primary"
                            >Register</router-link
                          >
                        </p>
                        <p>
                          © 2020 Nazox. Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by
                          Themesdesign
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>